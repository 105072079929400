export default {
  "charter-default": () => import("/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/applications/solresor-web/layouts/charter-default.vue"),
  "charter-destination": () => import("/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/applications/solresor-web/layouts/charter-destination.vue"),
  "charter-empty": () => import("/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/applications/solresor-web/layouts/charter-empty.vue"),
  "charter-page": () => import("/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/applications/solresor-web/layouts/charter-page.vue"),
  "charter-whitelabel": () => import("/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/applications/solresor-web/layouts/charter-whitelabel.vue"),
  "banner-default": () => import("/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/layouts/banner-default.vue"),
  "banner-trip": () => import("/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/layouts/banner-trip.vue"),
  default: () => import("/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/layouts/default.vue"),
  "noheader-layout": () => import("/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/layouts/noheader-layout.vue")
}