import { default as _91slug_93AblrMFNjFLMeta } from "/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/tourleader/[id]/[slug].vue?macro=true";
import { default as _91slug_93BgVN3NgRuCMeta } from "/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/titinerary/[slug].vue?macro=true";
import { default as _91name_93rRhn5wmwg7Meta } from "/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/shipinfo/[name].vue?macro=true";
import { default as _91id_93ALFqtO6Aq4Meta } from "/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/payment/[id].vue?macro=true";
import { default as _91id_93WPoQHx1XG7Meta } from "/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/omdome/[id].vue?macro=true";
import { default as _91tab_93wzXKKZleN1Meta } from "/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/mytrip/[tab].vue?macro=true";
import { default as _91slug_93lZF2h9XrxlMeta } from "/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/info/[slug].vue?macro=true";
import { default as _91tab_93PC9tlpIrFiMeta } from "/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/applications/solresor-web/pages/hotel/[id]/[tab].vue?macro=true";
import { default as _91id_93LL2Z1sokRIMeta } from "/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/fn/[id].vue?macro=true";
import { default as _91tab_93iKLObHQ2j1Meta } from "/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/applications/solresor-web/pages/destination/[id]/[tab].vue?macro=true";
import { default as _91slug_939eZ33Cn2WwMeta } from "/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/competition/[slug].vue?macro=true";
import { default as _91name_930wiiK4IAqLMeta } from "/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/applications/solresor-web/pages/campaign/[name].vue?macro=true";
import { default as _91ocr_930sj5WpcGD9Meta } from "/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/bookingfaq/[ocr].vue?macro=true";
import { default as _91ocr_93eJLf80YuRJMeta } from "/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/booking-finish/[ocr].vue?macro=true";
import { default as _91id_93yZbUCnJPnIMeta } from "/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/booking-confirmation/[id].vue?macro=true";
import { default as _91step_93GI1UdVEqrhMeta } from "/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/book/[tripid]/[step].vue?macro=true";
import { default as _91jwt_93MBbgfMM8w1Meta } from "/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/app/l/[jwt].vue?macro=true";
import { default as _91tab_93SBa7cMtQabMeta } from "/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/[triptype]/[slug]/[tab].vue?macro=true";
import { default as indexTT8idogYBlMeta } from "/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/app/index.vue?macro=true";
import { default as byebyeieBeUWkS4wqWMeta } from "/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/byebyeie.vue?macro=true";
import { default as gruppresorgZP4qEXNsMMeta } from "/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/applications/solresor-web/pages/campaign/gruppresor.vue?macro=true";
import { default as chartersearchiAj7Vc80x9Meta } from "/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/applications/solresor-web/pages/chartersearch.vue?macro=true";
import { default as complainttobPJBcM2dMeta } from "/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/complaint.vue?macro=true";
import { default as indexj6vRaczOjhMeta } from "/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/applications/solresor-web/pages/destination/index.vue?macro=true";
import { default as dinbokning_45aktuell1zk9H2sm65Meta } from "/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/dinbokning-aktuell.vue?macro=true";
import { default as faqNYDiIVkPJcMeta } from "/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/faq.vue?macro=true";
import { default as indexqtn11LwsMWMeta } from "/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/applications/solresor-web/pages/favourites/index.vue?macro=true";
import { default as _91id_934wf2FpgktIMeta } from "/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/giftcard/[id].vue?macro=true";
import { default as indexk8G60xPxJZMeta } from "/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/giftcard/index.vue?macro=true";
import { default as indexLag1esqJ65Meta } from "/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/applications/solresor-web/pages/hotel/index.vue?macro=true";
import { default as _91name_93GCZAwbvjPxMeta } from "/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/hotels/[name].vue?macro=true";
import { default as indexqVVktKWDqiMeta } from "/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/hotels/index.vue?macro=true";
import { default as indexCUynapfiYVMeta } from "/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/applications/solresor-web/pages/index.vue?macro=true";
import { default as dinbokningM53IufLnZcMeta } from "/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/mytrip/dinbokning.vue?macro=true";
import { default as newsletterwteE2Bavw9Meta } from "/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/newsletter.vue?macro=true";
import { default as offlineKwffnGe3YhMeta } from "/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/offline.vue?macro=true";
import { default as packagessearchh4mWhEut7QMeta } from "/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/applications/solresor-web/pages/packagessearch.vue?macro=true";
import { default as faileddlwsYCPUeFMeta } from "/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/payment/failed.vue?macro=true";
import { default as indexIU1QjA8cNKMeta } from "/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/review/index.vue?macro=true";
import { default as search60q2KlxXkwMeta } from "/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/search.vue?macro=true";
import { default as indexiqSP6eTKgbMeta } from "/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/tourleader/index.vue?macro=true";
import { default as verification_45donewuDeJTZMckMeta } from "/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/verification-done.vue?macro=true";
export default [
  {
    name: "tourleader-id",
    path: "/reseledare/:id()",
    component: () => import("/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/tourleader/[id]/[slug].vue")
  },
  {
    name: "titinerary",
    path: "/dagprogram",
    component: () => import("/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/titinerary/[slug].vue")
  },
  {
    name: "shipinfo",
    path: "/fartyg",
    component: () => import("/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/shipinfo/[name].vue")
  },
  {
    name: "payment",
    path: "/payment",
    component: () => import("/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/payment/[id].vue")
  },
  {
    name: "omdome",
    path: "/omdome",
    component: () => import("/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/omdome/[id].vue")
  },
  {
    name: "mytrip",
    path: "/dinresa",
    component: () => import("/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/mytrip/[tab].vue")
  },
  {
    name: "info",
    path: "/info",
    component: () => import("/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/info/[slug].vue")
  },
  {
    name: "hotel-id",
    path: "/hotel/:id()",
    component: () => import("/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/applications/solresor-web/pages/hotel/[id]/[tab].vue")
  },
  {
    name: "fn",
    path: "/fn",
    component: () => import("/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/fn/[id].vue")
  },
  {
    name: "destination-id",
    path: "/destination/:id()",
    component: () => import("/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/applications/solresor-web/pages/destination/[id]/[tab].vue")
  },
  {
    name: "competition",
    path: "/tavling",
    component: () => import("/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/competition/[slug].vue")
  },
  {
    name: "campaign",
    path: "/campaign",
    component: () => import("/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/applications/solresor-web/pages/campaign/[name].vue")
  },
  {
    name: "bookingfaq",
    path: "/boknings-faq",
    component: () => import("/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/bookingfaq/[ocr].vue")
  },
  {
    name: "booking-finish",
    path: "/booking-finish",
    component: () => import("/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/booking-finish/[ocr].vue")
  },
  {
    name: "booking-confirmation",
    path: "/booking-confirmation",
    component: () => import("/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/booking-confirmation/[id].vue")
  },
  {
    name: "book-tripid",
    path: "/boka/:tripid()",
    component: () => import("/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/book/[tripid]/[step].vue")
  },
  {
    name: "app-l",
    path: "/app/l",
    component: () => import("/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/app/l/[jwt].vue")
  },
  {
    name: "triptype-slug",
    path: "/:triptype()/:slug()",
    component: () => import("/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/[triptype]/[slug]/[tab].vue")
  },
  {
    name: "triptype-slug-tab",
    path: "/:triptype()/:slug()/:tab()",
    meta: _91tab_93SBa7cMtQabMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/[triptype]/[slug]/[tab].vue")
  },
  {
    name: "app",
    path: "/app",
    component: () => import("/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/app/index.vue")
  },
  {
    name: "app-l-jwt",
    path: "/app/l/:jwt()",
    component: () => import("/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/app/l/[jwt].vue")
  },
  {
    name: "book-tripid-step",
    path: "/boka/:tripid()/:step()",
    component: () => import("/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/book/[tripid]/[step].vue")
  },
  {
    name: "booking-confirmation-id",
    path: "/booking-confirmation/:id()",
    component: () => import("/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/booking-confirmation/[id].vue")
  },
  {
    name: "booking-finish-ocr",
    path: "/booking-finish/:ocr()",
    component: () => import("/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/booking-finish/[ocr].vue")
  },
  {
    name: "bookingfaq-ocr",
    path: "/boknings-faq/:ocr()",
    component: () => import("/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/bookingfaq/[ocr].vue")
  },
  {
    name: "byebyeie",
    path: "/byebyeie",
    component: () => import("/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/byebyeie.vue")
  },
  {
    name: "campaign-name",
    path: "/campaign/:name()",
    meta: _91name_930wiiK4IAqLMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/applications/solresor-web/pages/campaign/[name].vue")
  },
  {
    name: "campaign-gruppresor",
    path: "/campaign/gruppresor",
    component: () => import("/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/applications/solresor-web/pages/campaign/gruppresor.vue")
  },
  {
    name: "chartersearch",
    path: "/sok-charter",
    component: () => import("/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/applications/solresor-web/pages/chartersearch.vue")
  },
  {
    name: "competition-slug",
    path: "/tavling/:slug()",
    component: () => import("/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/competition/[slug].vue")
  },
  {
    name: "complaint",
    path: "/reklamation",
    meta: complainttobPJBcM2dMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/complaint.vue")
  },
  {
    name: "destination-id-tab",
    path: "/destination/:id()/:tab()",
    component: () => import("/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/applications/solresor-web/pages/destination/[id]/[tab].vue")
  },
  {
    name: "destination",
    path: "/destination",
    component: () => import("/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/applications/solresor-web/pages/destination/index.vue")
  },
  {
    name: "dinbokning-aktuell",
    path: "/dinbokning-aktuell",
    component: () => import("/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/dinbokning-aktuell.vue")
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/faq.vue")
  },
  {
    name: "favourites",
    path: "/favourites",
    component: () => import("/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/applications/solresor-web/pages/favourites/index.vue")
  },
  {
    name: "fn-id",
    path: "/fn/:id()",
    component: () => import("/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/fn/[id].vue")
  },
  {
    name: "giftcard-id",
    path: "/presentkort/:id()",
    component: () => import("/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/giftcard/[id].vue")
  },
  {
    name: "giftcard",
    path: "/presentkort",
    component: () => import("/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/giftcard/index.vue")
  },
  {
    name: "hotel-id-tab",
    path: "/hotel/:id()/:tab()",
    component: () => import("/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/applications/solresor-web/pages/hotel/[id]/[tab].vue")
  },
  {
    name: "hotel",
    path: "/hotel",
    component: () => import("/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/applications/solresor-web/pages/hotel/index.vue")
  },
  {
    name: "hotels-name",
    path: "/hotell/:name()",
    meta: _91name_93GCZAwbvjPxMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/hotels/[name].vue")
  },
  {
    name: "hotels",
    path: "/hotell",
    component: () => import("/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/hotels/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/applications/solresor-web/pages/index.vue")
  },
  {
    name: "info-slug",
    path: "/info/:slug()",
    meta: _91slug_93lZF2h9XrxlMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/info/[slug].vue")
  },
  {
    name: "mytrip-tab",
    path: "/dinresa/:tab()",
    meta: _91tab_93wzXKKZleN1Meta || {},
    component: () => import("/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/mytrip/[tab].vue")
  },
  {
    name: "mytrip-dinbokning",
    path: "/mytrip/dinbokning",
    component: () => import("/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/mytrip/dinbokning.vue")
  },
  {
    name: "newsletter",
    path: "/nyhetsbrev",
    component: () => import("/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/newsletter.vue")
  },
  {
    name: "offline",
    path: "/offline",
    component: () => import("/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/offline.vue")
  },
  {
    name: "omdome-id",
    path: "/omdome/:id()",
    meta: _91id_93WPoQHx1XG7Meta || {},
    component: () => import("/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/omdome/[id].vue")
  },
  {
    name: "packagessearch",
    path: "/sok-packages",
    component: () => import("/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/applications/solresor-web/pages/packagessearch.vue")
  },
  {
    name: "payment-id",
    path: "/payment/:id()",
    component: () => import("/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/payment/[id].vue")
  },
  {
    name: "payment-failed",
    path: "/payment/failed",
    component: () => import("/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/payment/failed.vue")
  },
  {
    name: "review",
    path: "/utvardera-resa",
    component: () => import("/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/review/index.vue")
  },
  {
    name: "search",
    path: "/sok",
    component: () => import("/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/search.vue")
  },
  {
    name: "shipinfo-name",
    path: "/fartyg/:name()",
    meta: _91name_93rRhn5wmwg7Meta || {},
    component: () => import("/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/shipinfo/[name].vue")
  },
  {
    name: "titinerary-slug",
    path: "/dagprogram/:slug()",
    component: () => import("/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/titinerary/[slug].vue")
  },
  {
    name: "tourleader-id-slug",
    path: "/reseledare/:id()/:slug()",
    component: () => import("/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/tourleader/[id]/[slug].vue")
  },
  {
    name: "tourleader",
    path: "/reseledare",
    component: () => import("/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/tourleader/index.vue")
  },
  {
    name: "verification-done",
    path: "/verification-done",
    meta: verification_45donewuDeJTZMckMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-04-08-0759/rtg-monorepo/src/layers/web/pages/verification-done.vue")
  }
]